@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

/* Example for local fonts */
@font-face {
  font-family: 'Shoulder';
  src: url('../public/fonts/Shoulder.woff2');
}

@layer base {
  html {
    font-size: 15px;
  }
}
